'use client';

import { usePathname } from 'next/navigation';

import { NavItemLink } from '@/components/NavItemLink';

import { NavItems } from '@/utils/navItems';

export default function MainSidebar() {
   const pathname = usePathname();

   return NavItems.map((item) => {
      return (
         <NavItemLink
            key={item.label}
            {...item}
            w={{ base: '20%', lg: '100%' }}
            active={
               item.link !== '/'
                  ? pathname.includes(item.link)
                  : pathname === '/'
            }
         />
      );
   });
}
